import { NgModule } from '@angular/core';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [ModalModule.forRoot(), TypeaheadModule.forRoot(), BsDatepickerModule.forRoot(), TooltipModule.forRoot()],
  exports: [ModalModule, TypeaheadModule, BsDatepickerModule, TooltipModule]
})
export class NgxBootstrapModule {}
