import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingModalService {
  private showModalTicks$ = new BehaviorSubject(0);

  closeModal() {
    this.showModalTicks$.next(this.showModalTicks$.getValue() - 1);
  }

  openModal() {
    this.showModalTicks$.next(this.showModalTicks$.getValue() + 1);
  }

  getModalStatus(): Observable<number> {
    return this.showModalTicks$;
  }
}
