import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit, OnDestroy {
  @ViewChild('loadingModal') modal;
  modalRef: BsModalRef;
  config = {
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-lg modal-dialog-centered',
    animated: false
  };

  currentMessage: string;
  intervalSubscription = new Subscription();

  getNextMessage(): void {
    let currentMessagesCount = 0;
    this.translationService
      .get('LOADING_MODAL.RANDOM_MESSAGES')
      .subscribe(x => (currentMessagesCount = Object.keys(x).length));
    let randomMessage = Math.floor(Math.random() * currentMessagesCount) + 1;
    this.currentMessage = this.translationService.instant('LOADING_MODAL.RANDOM_MESSAGES.MESSAGE_' + randomMessage);

    let messageIndex = 0;
    this.intervalSubscription = interval(5000).subscribe(() => {
      randomMessage = Math.floor(Math.random() * currentMessagesCount) + 1;
      return (this.currentMessage = this.translationService.instant(
        'LOADING_MODAL.RANDOM_MESSAGES.MESSAGE_' + randomMessage
      ));
    });
  }

  constructor(private modalService: BsModalService, private translationService: TranslateService) {}

  ngOnInit() {
    setTimeout(() => (this.modalRef = this.modalService.show(this.modal, this.config)));
    setTimeout(() => (this.getNextMessage(), 2000));
  }

  ngOnDestroy() {
    this.modalRef.hide();
    this.intervalSubscription.unsubscribe();
  }
}
