import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlgoliaResult } from './models/algoria-result.model';
import { InstantSearchConfig, SearchParameters } from 'angular-instantsearch/instantsearch/instantsearch';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Component({
  selector: 'app-algolia-autocomplete',
  templateUrl: './algolia-autocomplete.component.html',
  styleUrls: ['./algolia-autocomplete.component.scss']
})
export class AlgoliaAutocompleteComponent implements OnInit {
  @Input()
  placeholder: string;
  @Input()
  autocompleteCssClasses: string;
  @Input()
  linkedValue: string;
  @Output()
  currentOptionEvent = new EventEmitter<TypeaheadMatch>();
  @Output()
  clickEvent = new EventEmitter<void>();

  config: InstantSearchConfig;
  searchParams: SearchParameters;

  optionSelected(option: TypeaheadMatch) {
    this.currentOptionEvent.emit(option);
  }

  onClick() {
    this.clickEvent.emit();
  }

  constructor(private configurationService: ConfigurationService) {}

  ngOnInit() {
    this.config = {
      appId: this.configurationService.configuration.algoliaSettings.appId,
      apiKey: this.configurationService.configuration.algoliaSettings.apiKey,
      indexName: this.configurationService.configuration.algoliaSettings.indexName
    } as InstantSearchConfig;

    this.searchParams = { hitsPerPage: 10 } as SearchParameters;
  }
}
