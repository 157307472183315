import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ErrorModalService } from './error-modal.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit, OnDestroy {
  @ViewChild('template') modal;
  modalRef: BsModalRef;
  config = {
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-lg modal-dialog-centered'
  };

  closeModal() {
    this.errorModalService.closeModal();
  }

  constructor(private modalService: BsModalService, private errorModalService: ErrorModalService) {}

  ngOnInit() {
    setTimeout(() => (this.modalRef = this.modalService.show(this.modal, this.config)));
  }

  ngOnDestroy() {
    this.modalRef.hide();
  }
}
