import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorModalComponent } from './layouts/error-modal/error-modal.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { NgxBootstrapModule } from './ngx-bootstrap/ngx-bootstrap.module';
import { LoadingModalComponent } from './layouts/loading-modal/loading-modal.component';

import { NgAisModule } from 'angular-instantsearch';
import { AlgoliaAutocompleteComponent } from './layouts/algolia-autocomplete/algolia-autocomplete.component';
import { AutocompleteCustomIntegratorComponent } from './layouts/algolia-autocomplete/autocomplete-custom-integrator/autocomplete-custom-integrator.component';

defineLocale('fr', frLocale);

@NgModule({
  declarations: [
    ErrorModalComponent,
    LoadingModalComponent,
    AlgoliaAutocompleteComponent,
    AutocompleteCustomIntegratorComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, NgxBootstrapModule, TranslateModule.forChild({}), NgAisModule.forRoot()],
  exports: [
    ReactiveFormsModule,
    NgxBootstrapModule,
    TranslateModule,
    ErrorModalComponent,
    LoadingModalComponent,
    AlgoliaAutocompleteComponent
  ]
})
export class SharedModule {}
