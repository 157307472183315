import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ErrorModalService } from './shared/layouts/error-modal/error-modal.service';
import { LoadingModalService } from './shared/layouts/loading-modal/loading-modal.service';
import { delay } from 'rxjs/operators';
import { PreviousRouteService } from './shared/services/previous-route.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'conseilEnMobilite';
  errorModal$: Observable<boolean>;
  loadingModalTicks$: Observable<number>;

  constructor(
    translate: TranslateService,
    private errorModalService: ErrorModalService,
    private loadingModalService: LoadingModalService,
    private previousRouteService: PreviousRouteService
  ) {
    translate.setDefaultLang('fr');
    translate.use('fr');
  }

  ngOnInit(): void {
    this.errorModal$ = this.errorModalService.getModalStatus().pipe(delay(0));
    this.loadingModalTicks$ = this.loadingModalService.getModalStatus().pipe(delay(0));
  }
}
