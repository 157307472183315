import { refCount, publishReplay, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/configuration.model';

@Injectable()
export class ConfigurationService {
  public configuration: Configuration;
  public dataConfiguration: Configuration;
  public dataConfiguration$: Observable<Configuration>;
  public url: string;

  constructor(private http: HttpClient) {
    this.url = 'assets/appsettings.json';
  }

  public load(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.http.get<Configuration>(this.url).subscribe(value => {
        localStorage.setItem('configuration', JSON.stringify(value));
        this.configuration = value;
        resolve(true);
      });
    });
  }

  loadObservable(forceReload: boolean = false): Observable<Configuration> {
    if (!this.dataConfiguration$ || forceReload) {
      this.dataConfiguration$ = this.http.get(this.url).pipe(
        map(res => {
          this.configuration = res as Configuration;
          localStorage.setItem(
            'configuration',
            JSON.stringify(this.configuration)
          );
          return this.configuration;
        }),
        tap(data => {
          this.configuration = data;
          this.dataConfiguration = this.configuration;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.dataConfiguration$;
  }
}
