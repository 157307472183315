import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpErrorHandler } from './handlers/http-error.handler';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { ConfigurationService } from './services/configuration.service';

export function initializerFactory(config: ConfigurationService): any {
  // config loader at startup
  return () => config.load();
}

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    HttpErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [ConfigurationService],
      multi: true
    },
    ConfigurationService
  ]
})
export class CoreModule {}
