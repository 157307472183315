import {
  Component,
  OnInit,
  Inject,
  forwardRef,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges} from '@angular/core';
import { BaseWidget, NgAisInstantSearch } from 'angular-instantsearch';
import { connectAutocomplete } from 'instantsearch.js/es/connectors';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AlgoliaResult } from '../models/algoria-result.model';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Place, Locality } from 'src/app/shared/models/m2i/place.model';

@Component({
  selector: 'app-autocomplete-custom-integrator',
  templateUrl: './autocomplete-custom-integrator.component.html',
  styleUrls: ['./autocomplete-custom-integrator.component.scss']
})
export class AutocompleteCustomIntegratorComponent extends BaseWidget implements OnInit, OnChanges {
  @Input()
  placeholder: string;
  @Input()
  autocompleteCssClasses: string;
  @Input()
  linkedValue: string;
  @Output()
  currentOptionEvent = new EventEmitter<TypeaheadMatch>();
  @Output()
  clickEvent = new EventEmitter<void>();

  state: {
    query: string;
    refine: Function;
    indices: [
      {
        hits: Array<AlgoliaResult>;
      }
    ];
  };
  placeAutocomplete = new BehaviorSubject<any[]>([]);
  search = new FormControl();

  handleChange(value: string) {
    if (value && value.length > 2) {
      this.state.refine(value);
      this.placeAutocomplete.next(
        this.state.indices && this.state.indices.length > 0
          ? this.mapAlgoriaResultToPlace(this.state.indices[0].hits)
          : []
      );
    }
  }

  onPlaceSelect(selectedItem: TypeaheadMatch) {
    if (!selectedItem) {
      this.search.setValue(null);
    }
    this.currentOptionEvent.emit(selectedItem);
  }

  onClick() {
    this.search.setValue(null);
    this.clickEvent.emit();
  }

  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent
  ) {
    super('Autocomplete');
  }

  ngOnInit() {
    this.createWidget(connectAutocomplete, {});
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['linkedValue']) {
      this.search.setValue(change['linkedValue'].currentValue);
    }
  }

  private mapAlgoriaResultToPlace(results: AlgoliaResult[]): Place[] {
    const places = results.map(result => {
      return {
        Id: result.ID_TRIPPOINT,
        PointType: result.POINTTYPE,
        Name: result.NAME,
        Latitude: result._geoloc.lat,
        Longitude: result._geoloc.lng,
        Locality: {
          Name: result.LOCALITYNAME
        } as Locality
      } as Place;
    });

    places.map(x => (x.displayName = this.placeAutoCompleteDisplay(x)));
    return places;
  }

  private placeAutoCompleteDisplay(el: Place): string {
    let result = '';
    if (el.Name) {
      result = el.Name;
    }

    if (el.Locality && el.Locality.Name) {
      if (result) {
        result += ' - ';
      }
      result += el.Locality.Name;
    }

    return result;
  }
}
