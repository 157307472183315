import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {
  private showModal$: BehaviorSubject<boolean>;
  constructor() {
    this.showModal$ = new BehaviorSubject(false);
  }

  openModal() {
    this.showModal$.next(true);
  }

  closeModal() {
    this.showModal$.next(false);
  }

  getModalStatus(): Observable<boolean> {
    return this.showModal$.asObservable();
  }
}
