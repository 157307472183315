import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingModalService } from 'src/app/shared/layouts/loading-modal/loading-modal.service';
import { ErrorModalService } from 'src/app/shared/layouts/error-modal/error-modal.service';
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  requestsWithoutModal = ['assets', 'GetPlacesByBoundingBox'];

  constructor(
    private router: Router,
    private loadingModalService: LoadingModalService,
    private errorModalService: ErrorModalService
  ) {
    this.router.onSameUrlNavigation = 'reload';
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loadModal = this.requestsWithoutModal.filter(x => request.url.indexOf(x) !== -1).length === 0;
    if (loadModal) {
      this.loadingModalService.openModal();
    }

    return next.handle(request).pipe(
      map(event => {
        return event;
      }),
      catchError(error => {
        if (error.status === 500) {
          this.errorModalService.openModal();
        }
        return throwError(error);
      }),
      finalize(() => {
        if (loadModal) {
          this.loadingModalService.closeModal();
        }
      })
    );
  }
}
