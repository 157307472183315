import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ErrorModalService } from 'src/app/shared/layouts/error-modal/error-modal.service';

export type HandleError = <T>(operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class HttpErrorHandler {
  constructor(private errorModalService: ErrorModalService) {}

  createHandleError = (serviceName = '') => <T>(
    operation = 'operation',
    result = {} as T
    // tslint:disable-next-line:semicolon
  ) => this.handleError(result);

  handleError<T>(result = {} as T) {
    return (): Observable<T> => {
      this.errorModalService.openModal();
      return of(result);
    };
  }
}
